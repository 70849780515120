import type { OperationVariables } from '@apollo/client'

import type {
  ScratchcardOrdersQuery,
  ScratchcardState,
} from '~/@types/generated/backend/graphql-schema-types'

type Parameters = {
  limit: number
  status: ScratchcardState[]
}

const updateQuery = (
  previousResult: ScratchcardOrdersQuery,
  {
    fetchMoreResult,
    variables,
  }: {
    fetchMoreResult?: ScratchcardOrdersQuery
    variables?: OperationVariables
  },
): ScratchcardOrdersQuery => {
  if (!fetchMoreResult) return previousResult
  const { count, scratchcardOrders } = fetchMoreResult.scratchcardOrders

  if (
    variables?.limit ===
      previousResult.scratchcardOrders.scratchcardOrders.length &&
    variables.offset === 0
  ) {
    return fetchMoreResult
  }

  return {
    scratchcardOrders: {
      ...previousResult.scratchcardOrders,
      count,
      scratchcardOrders: [
        ...previousResult.scratchcardOrders.scratchcardOrders,
        ...scratchcardOrders,
      ],
    },
  }
}

export const useLazyScratchcardOrders = ({ limit, status }: Parameters) =>
  useFetchMoreWrapper<ReturnType<typeof useScratchcardOrdersLazyQuery>>(
    () =>
      useScratchcardOrdersLazyQuery(
        {
          filter: { status },
          limit,
          offset: 0,
        },
        { fetchPolicy: 'cache-and-network' },
      ),
    updateQuery,
    (result) => result?.scratchcardOrders.scratchcardOrders.length,
    (result) => result?.scratchcardOrders.count,
    'scratchcards.error',
  )
