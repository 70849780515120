<template>
  <MyOrdersScratchcardCard v-bind="data">
    <template #header>
      <MyOrdersScratchcardStatus
        v-bind="{ status }"
        :count="data.quantityActive"
      />
    </template>
  </MyOrdersScratchcardCard>
</template>

<script lang="ts" setup>
import type { ScratchcardOrderPayload } from '~/@types/generated/backend/graphql-schema-types'

type MyOrdersScratchcardCardActiveProps = { data: ScratchcardOrderPayload }

const props = defineProps<MyOrdersScratchcardCardActiveProps>()
const status = computed(() => props.data.state)
</script>
