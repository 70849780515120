<template>
  <MyOrdersCard v-bind="{ to }" footer-class="pb-xs" with-wavy-header>
    <template #header>
      <ScratchcardTitle :color="titleColor" size="small" :title="gameName" />
    </template>

    <template #header-end>
      <slot name="header" />
    </template>

    <template #list>
      <IconListItemId :id="orderId" />

      <IconListItemScratchcardTicketsAmount v-bind="{ quantity }" />
    </template>

    <template #cta>
      {{ cta }}
    </template>
  </MyOrdersCard>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'

import {
  type ScratchcardOrderPayload,
  ScratchcardState,
} from '~/@types/generated/backend/graphql-schema-types'

const CTA_PATH = 'myorders.card.scratchcard.cta'

type MyOrdersScratchcardCardProps = {
  gameName: ScratchcardOrderPayload['gameName']
  orderId: ScratchcardOrderPayload['orderId']
  quantity: ScratchcardOrderPayload['quantity']
  state: ScratchcardOrderPayload['state']
  titleColor: ScratchcardOrderPayload['titleColor']
}

const props = defineProps<MyOrdersScratchcardCardProps>()

const { t } = useI18n()

const cta = computed(() =>
  isScratched.value ? t(`${CTA_PATH}.scratchable`) : t(CTA_PATH),
)

const isScratched = computed(() => props.state === ScratchcardState.Scratched)

const to = computed<RouteLocationRaw>(() => {
  const path = `/my-orders/scratchcard/${props.orderId}`
  const query = isScratched.value ? { scratch: 'true' } : {}

  return { path, query }
})
</script>
