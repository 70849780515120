<template>
  <MyOrdersTab v-bind="{ fetchActive, fetchPast, visible }" type="scratchcard">
    <template #no-results>
      <MyOrdersNoResults variant="scratchcard" />
    </template>

    <template #active="{ result }">
      <li
        v-for="subscription in getScratchcards(result)"
        :key="subscription.orderId"
      >
        <MyOrdersScratchcardCardActive :data="subscription" />
      </li>
    </template>

    <template #past="{ result }">
      <li
        v-for="subscription in getScratchcards(result)"
        :key="subscription.orderId"
      >
        <MyOrdersScratchcardCardPast :data="subscription" />
      </li>
    </template>
  </MyOrdersTab>
</template>

<script lang="ts" setup>
import {
  type ScratchcardOrdersQuery,
  ScratchcardState,
} from '~/@types/generated/backend/graphql-schema-types'
import type { MyOrdersTabImplementationProps } from '~/features/MyOrders/types'

const ACTIVE_STATUS = [ScratchcardState.Purchased, ScratchcardState.Scratched]

const PAST_STATUS = [ScratchcardState.Revealed, ScratchcardState.Cancelled]

defineProps<MyOrdersTabImplementationProps>()

const fetchActive = () =>
  useLazyScratchcardOrders({
    limit: 20,
    status: ACTIVE_STATUS,
  })

const fetchPast = () =>
  useLazyScratchcardOrders({
    limit: 3,
    status: PAST_STATUS,
  })

const getScratchcards = (result: ScratchcardOrdersQuery) =>
  result.scratchcardOrders.scratchcardOrders
</script>
